import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center justify-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
        gold: "border-transparent text-emerald-black bg-gold-100 rounded-[80px] px-4 py-1 text-sm",
        equipment:
          "p4 rounded-sm bg-gold-100 py-1.5 border-none text-emerald-black",
        scientific_process:
          "p4 rounded-sm bg-emerald-500 py-1.5 border-none text-gold-50",
        service:
          "p4 rounded-sm bg-coffee-100 py-1.5 border-none text-emerald-black",
        s_process:
          "p4 rounded-sm bg-emerald-900 py-1.5 border-none text-gold-50",
        unpaid:
          "p4-medium font-medium text-sm border-transparent bg-warning-10 text-error-warning",
        paid: "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-success-background text-emerald-400",
        active:
          "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-emerald-500 text-interface-white",
        overage_requested:
          "w-[161px] p4-medium font-medium text-sm h-[34px] border-transparent bg-coffee-100 text-coffee-500",
        no_overage:
          "w-[161px] p4-medium font-medium text-sm h-[34px] border-transparent bg-success-background text-emerald-400",
        pending:
          "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-alert-10 text-alert-500",
        awaiting_payment:
          "p4-medium font-medium text-sm h-[34px] border-transparent bg-alert-10 text-alert-500",
        new: "p4-medium font-medium text-sm h-[34px] border-transparent bg-alert-10 text-alert-500",
        failed:
          "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-error-background text-error",
        cancelled:
          "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-error-background text-error",
        warning:
          "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-warning-10 text-warning-500",
        success:
          "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-success-background text-emerald-400",
        alert:
          "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-alert-10 text-alert-500",
        disabled:
          "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-coffee-100 text-coffee-500",
        voided:
          "w-28 p4-medium font-medium text-sm h-[34px] border-transparent bg-coffee-100 text-coffee-500",
        empty: "",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
